<template>
  <div class=container>
    <table class=table width="100%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2">Gallery of 3D Scanning Results</font></div></td>
          </tr>

          <tr>
            <td align="left"><h3></h3>
<blockquote>

  <p></p><div align="center"><router-link :to="{path:'/gallery/Diffuse_sl/pos1',query:{cid:this.$route.query.cid,pid:this.$route.query.pid}}"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/specularity_thumb.png" width="755" height="165" border="0"></router-link><br>
                              <span class="style1">.</span><br>
                           <router-link :to="{path:'/gallery/Diffuse_sl/pos1',query:{cid:this.$route.query.cid,pid:this.$route.query.pid}}">3D Scanning in the Presence of Specularities</router-link></div><br><p></p>

  <p></p><div align="center"><router-link :to="{path:'/gallery/Diffuse_sl/pos2',query:{cid:this.$route.query.cid,pid:this.$route.query.pid}}"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/shadow_thumb.png" width="508" height="165" border="0"></router-link><br>
                              <span class="style1">.</span><br>
                           <router-link :to="{path:'/gallery/Diffuse_sl/pos2',query:{cid:this.$route.query.cid,pid:this.$route.query.pid}}">3D Scanning in the Presence of Shadows</router-link></div><br><p></p>



</blockquote></td>
          </tr><tr>
            <td>&nbsp;</td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.container{
    width:100%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
}
.table{
        margin:auto;
}
</style>